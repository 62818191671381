import { Condition, DisplayParameter, MenuItem, NavtreeItem, NavtreeState, Operand, ParameterItem, ReportPageRoute } from 'app/shared/models';
import { Actions as NavtreeAction, Type as NavtreeActionType } from 'app/state/actions/navtree.actions';
import { cloneDeep } from 'lodash-es';

const initialState: NavtreeState = {
  items: [],
  routes: [],
  displayParameters: [],
  loading: false
};

export function reducer(state: NavtreeState = initialState, action: NavtreeAction): NavtreeState {

  switch (action.type) {
    case NavtreeActionType.LOAD_NAVTREE_CANCELED: {
      return { ...state, loading: false };
    }
    case NavtreeActionType.LOAD_NAVTREE_SUCCESS: {
      const routes: ReportPageRoute[] = [];
      const items = action.payload.length > 0 ? action.payload[0].items : [];
      const pageRoutes = getRoutes(items, routes);
      const displayParameters = getDisplayParameters(action.payload);

      return Object.assign({}, state, {
        items: action.payload,
        routes: pageRoutes,
        displayParameters: displayParameters,
        loading: false,
      });
    }
    case NavtreeActionType.LOAD_NAVTREE: {
      return Object.assign({}, state, {
        loading: true
      });
    }
    case NavtreeActionType.UPDATE_MISSING_VISIBILITY: {
      return {
        ...state,
        items: state.items.map(navtreeItem => {
          const filteredMenuItems = action.payload.filter(menuItem => menuItem.displayType === navtreeItem.type);
    
          if (filteredMenuItems.length === 0) {
            return navtreeItem;
          }
    
          return {
            ...navtreeItem,
            items: navtreeItem.items
              ? [...navtreeItem.items, ...filteredMenuItems]
              : [...filteredMenuItems]
          };
        })
      };
    }
    case NavtreeActionType.UPDATE_VISIBILITY: {
      const items: NavtreeItem[] = cloneDeep(state.items);
      const parameters = { ...action.params };
      const updatedItems = action.items ? [...action.items] : [];
      if (updatedItems) {
        updatedItems.forEach(updateItem => {
          items.forEach(treeItem => {
            const menuItem = treeItem.items.find(i => i.key === updateItem.key);
            if (menuItem) {
              if (menuItem.visible !== updateItem.visible) {
                menuItem.visible = updateItem.visible;
              }
            }
          });
        });
      } else {
        const companyParameters = parameters.companyParameters.find(cp => cp.companyId === parameters.currentCompanyId);
        const currentParameters = companyParameters ? companyParameters.parameters : [] as ParameterItem[];
        items.forEach(treeItem => {
          treeItem.items.forEach(menuItem => {
            if (menuItem.condition && menuItem.condition.context === 'Frontend') {
              menuItem.visible = evaluate(menuItem.condition, currentParameters);
            }
          });
        });
      }

      return Object.assign({}, state, {
        items: items
      });
    }
    default: {
      return state;
    }
  }

}

function getRoutes(pages: MenuItem[], routes: ReportPageRoute[]) {
  pages.forEach(page => {
    if (page.key) {
      const route: ReportPageRoute = { pageKey: page.key, route: page.route };
      routes.push(route);
      if (page.children.length > 0) {
        routes = getRoutes(page.children, routes);
      }
    }
  });
  return routes;
}

function getDisplayParameters(navItems: NavtreeItem[]) {
  const parameters: DisplayParameter[] = [];
  navItems.forEach(i => {
    i.items.forEach(p => {
      if (p.displayParameters != null && p.displayParameters.length > 0) {
        p.displayParameters.forEach(p => { parameters.push(p); });
      }
    });
  });
  return parameters;
}

function getValue(operand: Operand, parameters: ParameterItem[]): string {
  if (operand.source === 'Parameter') {
    const param = parameters.find(p => operand.field === p.name);
    return param ? param.value : null;
  } else if (operand.source === 'Const') {
    return operand.value;
  }

  return null;
}

function evaluate(condition: Condition, parameters: ParameterItem[]): boolean {
  if (condition.logic) {
    const children = condition.conditions.map(c => evaluate(c, parameters));
    if (condition.logic === 'And') {
      return children.every(c => c);
    } else {
      return children.some(c => c);
    }
  } else {
    const operand1Value = getValue(condition.operand1, parameters);
    const operand2Value = getValue(condition.operand2, parameters);
    return evaluateOperands(operand1Value, condition.operator, operand2Value);
  }
}

 function evaluateOperands(op1: string, operator: string, op2: string): boolean {

  if (operator === 'Eq') {
    if (op1 === null || op2 === null) {
      return (op1 === 'null' || op2 === 'null');
    }
    return op1 === op2;
  } else if (operator === 'Neq') {
    return op1 !== op2;
  } else if (op1 === null || op2 === null) {
    return false;
  } else if (operator === 'Lt') {
    return +op1 < +op2;
  } else if (operator === 'Lte') {
    return +op1 <= +op2;
  } else if (operator === 'Gt') {
    return +op1 > +op2;
  } else if (operator === 'Gte') {
    return +op1 >= +op2;
  }
  return false;
}
